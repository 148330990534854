import 'firebase/firestore';

import { Injectable } from '@angular/core';
import {
  AngularFirestore,
} from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { take } from 'rxjs/operators';

import { ListFilter } from '../interfaces/user';
import { UserMapper } from '../mappers/user-mapper';
import { UserModel } from '../models/user';
import { DBUtil } from '../utils/db-utils';
import { PlayerListFactory } from './utils/player-list-factory';


export function replaceUndefinedWithNull(obj: any) {
  for (const key in obj) {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      replaceUndefinedWithNull(obj[key]); // Recursively handle nested objects
    } else if (obj[key] === undefined) {
      obj[key] = null; // Replace undefined with null
    }
  }
  return obj;
}

export interface UserReadFilter {
  email?: string;
  id?: string;
  phone?: string;
}

@Injectable({
  providedIn: 'root',
})
export class UserDAOService {
  mapper = new UserMapper();
  readonly firestore = this.afs.firestore;
  private playerListFactory = new PlayerListFactory(this.firestore);
  constructor(
    private readonly afs: AngularFirestore,
    private readonly afn: AngularFireFunctions,
  ) { }

  async list(filter: ListFilter) {
    if (filter.lat !== undefined && filter.lng !== undefined) {
      return this.playerListFactory.getByLatLng(filter.lat, filter.lng);
    }

    const x = await this.afs.collection(DBUtil.User, (ref) => {
      if (!filter.email && !filter.displayName) {
        return ref.limit(100);
      }

      let value = filter.email ? filter.email : filter.displayName;
      const filterKey = filter.email ? 'email' : 'displayName';

      // Convert the value to lowercase for case-insensitive matching
      value = value?.toLowerCase() ?? '';

      // Generate the end code for substring matching
      // eslint-disable-next-line max-len
      const endCode = value.slice(0, -1) + String.fromCharCode(value.charCodeAt(value.length - 1) + 1);

      return ref
        .orderBy(filterKey) // Order the results by the filter key for consistent behavior
        .where(filterKey, '>=', value) // Case-insensitive substring match from the start
        // eslint-disable-next-line max-len
        .where(filterKey, '<', endCode) // Case-insensitive substring match until the next character
        // eslint-disable-next-line max-len
        .where(filterKey, '>=', value.charAt(0).toUpperCase() + value.slice(1)); // Case-insensitive matching of first character as uppercase
    });

    const docItem = await x.get().toPromise();
    if (!docItem) return [];
    return docItem.docs.map((doc) => doc.data());
  }

  async create(userModel: UserModel) {
    const collectionRef = this.afs.collection(DBUtil.User);
    const docRef =
      userModel.uid === null ?
        collectionRef.doc() :
        collectionRef.doc(userModel.uid);
    userModel.ref = docRef as any;

    try {
      await docRef.set(this.mapper.toMap(userModel));
      if (!docRef) return undefined;
      const docRefItem = await docRef.get().toPromise();
      if (!docRefItem) return undefined;
      return docRefItem.id;
    } catch (e) {
      throw (e);
    }
  }

  async update(userModel: UserModel) {
    const collectionRef = this.afs.collection(DBUtil.User);
    const docRef = await collectionRef.doc(userModel.uid);
    const mappedUser = replaceUndefinedWithNull((this.mapper.toMap(userModel)));

    try {
      const updateFirebaseDoc = docRef.set(mappedUser, { merge: true });

      const updateAuth = this.afn.httpsCallable('users-triggers-updateFirebaseAuth');
      const updateRequest = {
        displayName: userModel.displayName,
        phoneNumber: userModel.phoneNumber,
        photoURL: userModel.photoURL,
      };
      const updateFirebaseAuth = updateAuth(updateRequest).pipe(take(1)).toPromise();

      return Promise.all([updateFirebaseDoc, updateFirebaseAuth])
        .then(() => true);
    } catch (error: unknown) {
      throw (error);
    }
  }

  async read(filter: UserReadFilter) {
    let type: 'email' | 'phone' | 'id';
    if (filter.email) type = 'email';
    else if (filter.id) type = 'id';
    else if (filter.phone) type = 'phone';

    const snapshot = this.afs.collection(DBUtil.User, (ref) => {
      switch (type) {
        case 'email':
          return ref
            .where('email', '==', filter.email)
            .limit(1);
        case 'phone':
          return ref
            .where('phoneNumber', '==', filter.phone)
            .limit(1);
        case 'id':
          return ref
            .where('uid', '==', filter.id)
            .limit(1);
        default: return ref.limit(1);
      }
    });

    const x = await snapshot.get().toPromise();
    if (!x || !x.docs || x.docs.length === 0) {
      return undefined;
    }
    const firstDocument = x.docs[0];
    const userData = firstDocument.data() as any;
    return {
      ...userData,
      id: firstDocument.id,
    };
  }
}

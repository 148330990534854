import { AngularFirestore } from '@angular/fire/compat/firestore';
import { EventItem } from '@index/interfaces';
import { DBUtil } from '@index/utils/db-utils';
import firebase from 'firebase/compat/app';
import * as geofire from 'geofire-common';

import { EventItemMapper } from '../../mappers/event-item-mapper';
import { UserMapper } from '../../mappers/user-mapper';

export class GeoFire {
  getBounds = (center: [number, number], radiusInM: number) => {
    // Each item in 'bounds' represents a startAt/endAt pair. We have to issue
    // a separate query for each pair. There can be up to 9 pairs of bounds
    // depending on overlap, but in most cases there are 4.
    return geofire.geohashQueryBounds(center, radiusInM) as geofire.GeohashRange[];
  };

  getEventsInBounds = (
    bounds: string[][],
    firestore: AngularFirestore,
  ) => {
    const promises = [];
    for (const b of bounds) {
      const q = firestore.collection(
        DBUtil.EventItem,
        (ref) => {
          return ref.orderBy('location.hash')
            .startAt(b[0]).endAt(b[1]);
        },
      );

      promises.push(q.get());
    }
    return promises;
  };

  getPlayersInBounds = (
    bounds: string[][],
    db: firebase.firestore.CollectionReference<firebase.firestore.DocumentData>,
  ) => {
    const promises = [];
    for (const b of bounds) {
      const q = db.orderBy('defaultCity.hash').startAt(b[0]).endAt(b[1]);
      promises.push(q.get());
    }
    return promises;
  };

  getTeamsInBounds = (
    bounds: string[][],
    db: firebase.firestore.CollectionReference<firebase.firestore.DocumentData>,
  ) => {
    const promises = [];
    for (const b of bounds) {
      const q = db.orderBy('location.hash').startAt(b[0]).endAt(b[1]);
      promises.push(q.get());
    }
    return promises;
  };

  mergeAllEventsWithoutMapping = (
    promises: Promise<firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>>[],
    radiusInM: number,
    center: [number, number],
  ) => {
    return Promise.all(promises).then((snapshots) => {
      const matchingDocs = [];

      for (const snap of snapshots) {
        for (const doc of snap.docs) {
          const lat = doc.get('location.lat');
          const lng = doc.get('location.lng');

          // We have to filter out a few false positives due to GeoHash
          // accuracy, but most will match
          const distanceInKm = geofire.distanceBetween([lat, lng], center);
          const distanceInM = distanceInKm * 1000;
          if (distanceInM <= radiusInM) {
            matchingDocs.push({
              id: doc.id,
              ...doc.data() } as EventItem);
          }
        }
      }
      return matchingDocs;
    });
  };

  mergeAllEvents = (
    promises: Promise<firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>>[],
    radiusInM: number,
    center: [number, number],
  ) => {
    return Promise.all(promises).then((snapshots) => {
      const matchingDocs = [];

      for (const snap of snapshots) {
        for (const doc of snap.docs) {
          const lat = doc.get('location.lat');
          const lng = doc.get('location.lng');

          // We have to filter out a few false positives due to GeoHash
          // accuracy, but most will match
          const distanceInKm = geofire.distanceBetween([lat, lng], center);
          const distanceInM = distanceInKm * 1000;
          if (distanceInM <= radiusInM) {
            matchingDocs.push(new EventItemMapper().fromSnapshot(doc)!);
          }
        }
      }
      return matchingDocs;
    });
  };

  mergeAllPlayers = (
    promises: Promise<firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>>[],
    radiusInM: number,
    center: [number, number],
  ) => {
    return Promise.all(promises).then((snapshots) => {
      const matchingDocs = [];

      for (const snap of snapshots) {
        for (const doc of snap.docs) {
          const lat = doc.get('defaultCity.lat');
          const lng = doc.get('defaultCity.lng');

          // We have to filter out a few false positives due to GeoHash
          // accuracy, but most will match
          const distanceInKm = geofire.distanceBetween([lat, lng], center);
          const distanceInM = distanceInKm * 1000;
          if (distanceInM <= radiusInM) {
            matchingDocs.push(new UserMapper().fromSnapshot(doc));
          }
        }
      }
      return matchingDocs;
    });
  };

  mergeAllTeams = (
    promises: Promise<firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>>[],
    radiusInM: number,
    center: [number, number],
  ) => {
    return Promise.all(promises).then((snapshots) => {
      const matchingDocs = [] as firebase.firestore.QueryDocumentSnapshot[];

      for (const snap of snapshots) {
        for (const doc of snap.docs) {
          const lat = doc.get('location.lat');
          const lng = doc.get('location.lng');

          // We have to filter out a few false positives due to GeoHash
          // accuracy, but most will match
          const distanceInKm = geofire.distanceBetween([lat, lng], center);
          const distanceInM = distanceInKm * 1000;
          if (distanceInM <= radiusInM) {
            matchingDocs.push(doc);
          }
        }
      }
      return matchingDocs;
    });
  };
}

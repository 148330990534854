import firebase from 'firebase/compat/app';

import { ListFilter } from '../../interfaces/user';
import { DBUtil } from '../../utils/db-utils';
import { GeoFire } from './geofire';

export class PlayerListFactory {
  constructor(
    readonly firestore: firebase.firestore.Firestore,
    private readonly geoFire: GeoFire = new GeoFire(),
  ) {}

  async getByLatLng(lat: number, lng: number) {
    const ref = await this.firestore.collection(DBUtil.User);

    const radiusInM = 200 * 1000;
    const center:[number, number] = [lat, lng];
    const bounds = await this.geoFire.getBounds(center, radiusInM);
    const playersInBounds = this.geoFire.getPlayersInBounds(bounds, ref);
    return await this.geoFire.mergeAllPlayers(playersInBounds, radiusInM, center);
  }


  async get(filter: ListFilter) {
    switch (true) {
      case filter.lat !== undefined && filter.lng !== undefined:
        return await this.getByLatLng(filter.lat, filter.lng);
      default:
        throw new Error('invalid filter for player list');
    }
  }
}

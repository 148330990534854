import 'firebase/firestore';

import firebase from 'firebase/compat/app';

import { DBUtil } from '../utils/db-utils';
import { firestorePopulateSnapshot } from '../utils/populaters';

export abstract class DBModel {
  ref: firebase.firestore.DocumentReference<firebase.firestore.DocumentData> | undefined;
  id: string | undefined;

  protected constructor(
    ref?: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>,
    public readonly populator = firestorePopulateSnapshot,
  ) {
    this.ref = ref;
    this.id = ref && ref.id ? ref.id : undefined;
  }

  populate(id: string, dbName: DBUtil) {
    if (!this.ref) return;
    return firestorePopulateSnapshot(this.ref, id, dbName);
  }
}

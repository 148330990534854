import { inject, Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { replaceUndefinedWithNull, UserReadFilter } from '@index/daos/user-dao';
import { PlayerListFactory } from '@index/daos/utils/player-list-factory';
import { Availability, ListFilter, User } from '@index/interfaces';
import { UserMapper } from '@index/mappers/user-mapper';
import { UserModel } from '@index/models/user';
import { DBUtil } from '@index/utils/db-utils';
import { GthUserModel, SrvAvailabilityModel } from '@sentinels/models';
import { distanceBetween } from 'geofire-common';
import { combineLatest, filter, from, lastValueFrom, Observable, of, zip } from 'rxjs';
import { map, shareReplay, switchMap, take } from 'rxjs/operators';

import { FirestoreService } from '../core/firebase.service';

@Injectable({ providedIn: 'root' })
export class UserService extends FirestoreService<User> {
  private functions = inject(AngularFireFunctions);

  protected basePath: string = 'users';

  private mapper = new UserMapper();
  private playerListFactory = new PlayerListFactory(this.firestore as any);

  /**
     * Gets joiners of an event and maps them to users.
     * @param {string}userIds
     * @return {Observable<JoinerUserMapper>}
     */
  getUsers(userIds: string[]): Observable<GthUserModel[]> {
      const userObservables = userIds.map((id) => this.getUser$(id));
      if (userObservables.length === 0) {
        return of([]);
      }

      return zip(...userObservables).pipe(shareReplay(1));
  }

  getUsersByLocation$(lat: number, lng: number, withAvailability?: boolean) {
    const radiusInM = 200 * 1000;
    const center: [number, number] = [lat, lng];

    interface UserWithId extends User {
      id: string;
    }
    const users$ = from(
      this.firestore
        .collection(DBUtil.User)
        .get(),
    ).pipe(
      map((snapshot) => snapshot.docs.map((doc) => {
        const userData = doc.data() as UserWithId;
        return { ...userData, id: doc.id };
      })),
      shareReplay(1),
    );
    return users$.pipe(
      /** Filter events that within 200km of the specified location */
      map((users) => {
        return users.filter((user) => {
          if (!user?.defaultCity?.lat || !user?.defaultCity?.lng) return false;

          const distanceInKm = distanceBetween(
            [user.defaultCity.lat, user.defaultCity.lng],
            center,
          );
          const distanceInM = distanceInKm * 2000;
          return distanceInM <= radiusInM;
        });
      }),
      /** Convert EventItem[] to GthEventItemModel[] */
      map((users) => users.map((user) => {
        return new GthUserModel(user.uid, user);
      })),
      switchMap((users) => {
        if (!withAvailability) return of(users);

        const usersWithAvailability = users.map((user) => {
          const availability = this.getUserAvailability$(user.uid);
          return availability.pipe(
            map((availability) => {
              user.userAvailability = new SrvAvailabilityModel(user.uid, availability);
              return user;
            }),
          );
        });

        return combineLatest(usersWithAvailability);
      }),
    );
    }

  /**
     * Gets user by ID.
     * @param {string}userId
     * @return {Observable<GthUserModel>}
     */
  getUser$(userId: string): Observable<GthUserModel | null> {
    return this.doc$<User>(userId).pipe(
      map((user) => {
        return user?.uid ? new GthUserModel(user.uid, user) : null;
      }),
    );
  }

  /**
   * Gets user by id .
   * @param {string} userId Id for user
   * @param {User} user Firebase Auth User
   * @return {Observable<GthUserModel>} model of user
   */
  getUser(userId: string, user: User): Observable<GthUserModel> {
    return this.doc$<User>(userId).pipe(map((doc) => {
      const data = {
        ...doc,
      };

      data.uid = user.uid;
      data.displayName = user.displayName ?? '';
      data.email = user.email ?? '';
      data.photoURL = user.photoURL ?? '';
      data.emailVerified = user.emailVerified;
      return new GthUserModel(doc.uid ?? '', data);
    }));
  }

  private getUserAvailability$(userId: string): Observable<Availability | null> {
    if (!userId) return of(null);

    const userAvailability = this.firestore
      .collection<Availability>('srv_user_availability')
      .doc(userId)
      .valueChanges({ idField: 'id' });

    return userAvailability;
  }

  // TODO(srevier): integrate with ngrx and cache
  getUserByEmail$(email: string): Observable<GthUserModel | null> {
    return this.read$({ email }).pipe(
      filter(this._notNull),
      map((user) => new GthUserModel(user.uid, user)),
    );
  }

  getUserByPhone$(phone: string): Observable<GthUserModel | null> {
    return this.read$({ phone }).pipe(
      filter(this._notNull),
      map((user) => new GthUserModel(user.uid, user)),
    );
  }

  getUserById$(id: string): Observable<GthUserModel | null> {
    return this.read$({ id }).pipe(
      filter(this._notNull),
      map((user) => new GthUserModel(user.uid, user)),
    );
  }

  updateOne(id: string, dataToUpdate:{[key: string]: any}) {
    return this.update(id, dataToUpdate);
  }

  deleteUser(id: string) {
    return this.delete(id);
  }

  async list(filter: ListFilter) {
    if (filter.lat !== undefined && filter.lng !== undefined) {
      return this.playerListFactory.getByLatLng(filter.lat, filter.lng);
    }

    const x = this.firestore.collection(
      this.basePath,
      (ref) => {
      if (!filter.email && !filter.displayName) {
        return ref.limit(100);
      }

      let value = filter.email ? filter.email : filter.displayName;

      const filterKey = filter.email ? 'email' : 'displayName';

      // Convert the value to lowercase for case-insensitive matching
      value = value?.toLowerCase() ?? '';

      // Generate the end code for substring matching
      // eslint-disable-next-line max-len
      const endCode = value.slice(0, -1) + String
        .fromCharCode(value.charCodeAt(value.length - 1) + 1);

      return ref
        .orderBy(filterKey) // Order the results by the filter key for consistent behavior
        .where(filterKey, '>=', value) // Case-insensitive substring match from the start
        // eslint-disable-next-line max-len
        .where(filterKey, '<', endCode) // Case-insensitive substring match until the next character
        // eslint-disable-next-line max-len
        .where(filterKey, '>=', value.charAt(0).toUpperCase() + value.slice(1)); // Case-insensitive matching of first character as uppercase
      },
    );

    const docItem = await lastValueFrom(x.get());

    if (!docItem) return [];

    return docItem.docs.map((doc) => doc.data());
  }

  async createUser(userModel: UserModel) {
    const collectionRef = this.collection;

    const docRef = userModel.uid === null ?
        collectionRef.doc() : collectionRef.doc(userModel.uid);

    try {
      await docRef.set(this.mapper.toMap(userModel));

      if (!docRef) return undefined;

      const docRefItem = await lastValueFrom(docRef.get());

      if (!docRefItem.exists) return undefined;

      return docRefItem.id;
    } catch (e) {
      throw (e);
    }
  }

  async updateUser(userModel: UserModel | GthUserModel) {
    const user = userModel instanceof GthUserModel ?
      userModel.copy as UserModel : userModel;

    const docRef = this.doc(userModel.uid);

    const mappedUser = replaceUndefinedWithNull((this.mapper.toMap(user)));

    try {
      const updateFirebaseDoc = docRef.set(mappedUser, { merge: true });

      const updateAuth = this.functions.httpsCallable('users-triggers-updateFirebaseAuth');

      const updateRequest = {
        displayName: userModel.displayName,
        phoneNumber: userModel.phoneNumber,
        photoURL: userModel.photoURL,
      };

      const updateFirebaseAuth = lastValueFrom(updateAuth(updateRequest).pipe(take(1)));

      return Promise.all([updateFirebaseDoc, updateFirebaseAuth])
        .then(() => true);
    } catch (error: unknown) {
      throw (error);
    }
  }

  read$(filter: UserReadFilter) {
    let type: 'email' | 'phone' | 'id';
    if (filter.email) type = 'email';
    else if (filter.id) type = 'id';
    else if (filter.phone) type = 'phone';

    return this.collection$(
      (ref) => {
        switch (type) {
          case 'email':
            return ref
              .where('email', '==', filter.email)
              .limit(1);
          case 'phone':
            return ref
              .where('phoneNumber', '==', filter.phone)
              .limit(1);
          case 'id':
            return ref
              .where('uid', '==', filter.id)
              .limit(1);
          default:
            return ref.limit(1);
        }
      },
      { idField: 'id' },
    ).pipe(
      map((users) => {
        if (!users?.length) return null;

        return users[0];
      }),
    );
  }

  private _notNull = <T>(value?: T | null): value is T => !!value;
}
